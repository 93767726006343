import { IconProps } from '@chakra-ui/icons';
import { Icon } from '@chakra-ui/react';

export const CheckIcon = (props: IconProps) => {
    const { fill = '#0085CA', ...rest } = props;

    return (
        <Icon viewBox="0 0 48 48" {...rest}>
            <g>
                <g>
                    <rect width="48" height="48" fill="none" />
                </g>
                <g>
                    <path
                        d="M24,6A18,18,0,1,1,6,24,18.1,18.1,0,0,1,24,6m0-4A22,22,0,1,0,46,24,21.9,21.9,0,0,0,24,2Z"
                        fill={fill as string}
                    />
                    <path
                        d="M18.6,32.4l-6-5.9a2.1,2.1,0,0,1-.2-2.7,1.9,1.9,0,0,1,3-.2L20,28.2,32.6,15.6a2,2,0,0,1,2.8,2.8l-14,14A1.9,1.9,0,0,1,18.6,32.4Z"
                        fill={fill as string}
                    />
                </g>
            </g>
        </Icon>
    );
};
