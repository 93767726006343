import { IconProps } from '@chakra-ui/icons';
import { Icon } from '@chakra-ui/react';

export const YouTubeIcon = (props: IconProps) => {
    return (
        <Icon viewBox="0 0 17 17" {...props}>
            <path d="M 16.823,4.047 A 2.306,2.306 0 0 0 14.997,2.301 36.116,36.116 0 0 0 8.507,1.814 36.649,36.649 0 0 0 1.973,2.301 2.253,2.253 0 0 0 0.142,4.047 a 26.632,26.632 0 0 0 -0.325,4.262 22.091,22.091 0 0 0 0.365,4.262 2.31,2.31 0 0 0 1.825,1.745 37.34,37.34 0 0 0 6.536,0.487 37.34,37.34 0 0 0 6.536,-0.487 2.255,2.255 0 0 0 1.828,-1.745 31.143,31.143 0 0 0 0.406,-4.262 28.407,28.407 0 0 0 -0.49,-4.262 z M 6.307,11.15 V 5.467 l 4.952,2.841 z" />
        </Icon>
    );
};
