import axios from 'axios';

import { getAuthHeaders } from '@/lib/httpsAgent';

import { Invoice, OptionsWithServerSideRequest } from '@/models/api';
import { EnvironmentService } from '@/services/EnvironmentService';

const BASE_URL = EnvironmentService.getPublicApiURL().concat('/invoices');

export class InvoiceService {
    static async getInvoice(invoiceNo: string, opts?: OptionsWithServerSideRequest) {
        const headers = getAuthHeaders(opts);
        headers['x-response-type'] = 'arraybuffer';
        const url = new URL(BASE_URL.concat('/download'));
        url.searchParams.set('invoiceNo', invoiceNo);
        return axios.get(url.toString(), {
            headers,
            signal: opts?.abortController?.signal,
            responseType: 'arraybuffer'
        });
    }

    static async getInvoicesByYear(year: number | string, opts?: OptionsWithServerSideRequest) {
        const headers = getAuthHeaders(opts);

        const url = new URL(BASE_URL.concat(`/years/${year}`));

        return await axios
            .get<Invoice[]>(url.toString(), { headers, signal: opts?.abortController?.signal })
            .then((response) => response.data);
    }

    static async getInvoicesYears(opts?: OptionsWithServerSideRequest) {
        const headers = getAuthHeaders(opts);

        const url = new URL(BASE_URL.concat('/years'));

        return await axios
            .get<number[]>(url.toString(), { headers })
            .then((response) => response.data);
    }
}
