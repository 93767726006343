import { IconProps } from '@chakra-ui/icons';
import { Icon } from '@chakra-ui/react';

export const DartBoardIcon = (props: IconProps) => {
    return (
        <Icon viewBox="0 0 400 400" {...props}>
            <path
                d="M398.885,74.765c-2.178-4.879-7.118-7.958-12.544-7.662l-55.774,3.042l3.039-55.773c0.292-5.328-2.787-10.369-7.657-12.544
	c-1.675-0.749-3.455-1.128-5.288-1.128c-3.463,0-6.719,1.35-9.168,3.799L270.65,45.341c-2.451,2.448-3.801,5.703-3.801,9.171
	l0,60.518l-3.164,3.164c-27.955-23.975-64.259-38.484-103.889-38.484C71.684,79.709,0,151.393,0,239.507
	c0,88.109,71.684,159.794,159.796,159.794c88.111,0,159.795-71.685,159.795-159.794c0-38.914-13.988-74.617-37.191-102.363
	l3.281-3.281l60.521-0.001c3.414,0,6.754-1.382,9.167-3.796l40.846-40.847C399.989,85.447,401.061,79.634,398.885,74.765z
	 M292.958,239.507c0,73.424-59.737,133.162-133.163,133.162c-73.427,0-133.164-59.738-133.164-133.162
	c0-73.43,59.737-133.163,133.164-133.163c32.286,0,61.924,11.553,85.001,30.74l-30.941,30.94
	c-15.04-11.403-33.772-18.183-54.061-18.183c-49.44,0-89.664,40.222-89.664,89.665c0,49.438,40.224,89.66,89.664,89.66
	c49.439,0,89.663-40.222,89.663-89.66c0-19.565-6.305-37.686-16.981-52.442l31.007-31.006
	C281.909,178.905,292.958,207.938,292.958,239.507z M222.825,239.507c0,34.754-28.275,63.028-63.029,63.028
	c-34.755,0-63.031-28.274-63.031-63.028c0-34.759,28.275-63.033,63.031-63.033c12.932,0,24.965,3.922,34.979,10.629l-42.985,42.988
	c-5.199,5.2-5.199,13.632,0,18.832c2.6,2.598,6.009,3.9,9.416,3.9c3.409,0,6.816-1.302,9.416-3.9l42.678-42.681
	C219.328,215.906,222.825,227.302,222.825,239.507z"
            />
        </Icon>
    );
};
