import { CheckoutStatusError } from '@/models/api/Order';
import { AddressData } from '@/models/api/ProfileInfo';

export function validateShippingAddress(data: AddressData) {
    const requiredAddressFields = ['street', 'buildingNo', 'zipCode', 'city', 'mobile'];

    const missingFields = requiredAddressFields.filter(
        (fieldName) => !data[fieldName as keyof AddressData]?.trim()
    );

    if (!data.email) {
        missingFields.push('email');
    }

    if (!missingFields.length) {
        return null;
    }

    return {
        title: CheckoutStatusError.MissingAddressData,
        list: missingFields
    };
}

export function validateInpostShippingAddress(data: AddressData) {
    const requiredAddressFields = ['inpostLocker', 'name', 'surname', 'mobile', 'email'];

    const missingFields = requiredAddressFields.filter(
        (fieldName) => !data[fieldName as keyof AddressData]?.trim()
    );

    if (!missingFields.length) {
        return null;
    }

    return {
        title: CheckoutStatusError.MissingAddressData,
        list: missingFields
    };
}
