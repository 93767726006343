import { IconProps } from '@chakra-ui/icons';
import { Icon } from '@chakra-ui/react';

export const InstagramIcon = (props: IconProps) => {
    return (
        <Icon viewBox="0 0 16.913 16.913" {...props}>
            <path d="m 4.997,0 a 5,5 0 0 0 -5,5 v 6.919 a 5,5 0 0 0 5,5 h 6.919 a 5,5 0 0 0 5,-5 V 4.997 a 5,5 0 0 0 -5,-5 z m 8.072,3.075 A 0.769,0.769 0 1 1 12.3,3.844 0.771,0.771 0 0 1 13.069,3.075 Z M 8.456,4.227 A 4.228,4.228 0 1 1 4.227,8.456 4.231,4.231 0 0 1 8.456,4.227 Z m 0,0.769 A 3.459,3.459 0 1 0 11.915,8.455 3.465,3.465 0 0 0 8.456,4.997 Z" />
        </Icon>
    );
};
