import { IconProps } from '@chakra-ui/icons';
import { Icon } from '@chakra-ui/react';

export const TwitterIcon = (props: IconProps) => {
    return (
        <Icon viewBox="0 0 19 15.440369" {...props}>
            <path d="m 19,1.827105 a 7.8,7.8 0 0 1 -2.24,0.615 A 3.908,3.908 0 0 0 18.475,0.28610497 7.827,7.827 0 0 1 15.999,1.231105 3.9,3.9 0 0 0 9.357,4.785105 11.068,11.068 0 0 1 1.322,0.71210497 3.906,3.906 0 0 0 2.529,5.918105 3.9,3.9 0 0 1 0.762,5.429105 v 0.049 a 3.9,3.9 0 0 0 3.126,3.823 3.9,3.9 0 0 1 -1.026,0.136 3.755,3.755 0 0 1 -0.734,-0.071 3.907,3.907 0 0 0 3.641,2.708 7.814,7.814 0 0 1 -4.841,1.669 7.669,7.669 0 0 1 -0.928,-0.055 11.014,11.014 0 0 0 5.975,1.752 11.015,11.015 0 0 0 11.091,-11.09 c 0,-0.169 0,-0.339 -0.01,-0.5 A 7.924,7.924 0 0 0 19,1.827105 Z" />
        </Icon>
    );
};
