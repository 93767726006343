import { ComponentStyleConfig } from '@chakra-ui/react';

export const Button: ComponentStyleConfig = {
    baseStyle: {
        borderRadius: 21
    },
    variants: {
        underlineBrand: {
            color: 'inherit',
            fontWeight: 'regular',
            width: 'max-content',
            textUnderlineOffset: '5px',
            textDecoration: 'underline',
            textDecorationThickness: '1px',
            textDecorationColor: 'brand.darker',
            px: 0,
            _hover: {
                fontWeight: 'semibold',
                textDecorationThickness: '1px',
                textDecorationColor: 'grey.main',
                letterSpacing: '-0.14px'
            }
        }
    }
};
