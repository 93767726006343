const brandColor = '#0085CA';
const colors = {
    brand: '#0085CA',
    blue: {
        main: brandColor,
        50: '#E4F2F9',
        100: '#E4E9F2',
        200: '#D0DCEB',
        500: brandColor,
        700: '#144872'
    },
    red: {
        main: '#E95E5F',
        400: '#FF8788',
        500: '#E95E5F'
    },
    yellow: {
        main: '#FEEEC6',
        100: '#FEEEC6',
        400: '#ECC94B'
    },
    grey: {
        main: '#181E20',
        50: '#F7FAFC',
        100: '#F1F5F9',
        200: '#EFEFEF',
        300: '#E7E8E9',
        400: '#D4D7DC',
        450: '#BFC4CA',
        500: '#BABCBC',
        600: '#90969D',
        700: '#747879',
        800: '#464B4D',
        900: '#181E20'
    },
    orange: {
        main: '#FFC355'
    },
    user: {
        main: '#F7F7F7',
        none: '#ffffff',
        lame: '#E7E7E7',
        leader: '#FEEEC6',
        transparent: 'transparent'
    },
    myProgress: {
        gold: {
            dark: '#B57F04',
            light: '#E6B952'
        },
        grey: {
            light: '#E1EDF2',
            dark: '#A7BDC7'
        }
    }
};

export default colors;
