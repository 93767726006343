import axios from 'axios';

import { getAuthHeaders } from '@/lib/httpsAgent';

import { OptionsWithServerSideRequest } from '@/models/api';
import { EnvironmentService } from '@/services/EnvironmentService';

const BASE_URL = EnvironmentService.getPublicApiURL().concat('/util');

export class UtilsService {
    static async getServerDate(opts?: OptionsWithServerSideRequest) {
        const headers = getAuthHeaders(opts);

        const url = new URL(BASE_URL.concat('/time'));

        return await axios
            .get<{
                time: number;
            }>(url.toString(), { headers, signal: opts?.abortController?.signal })
            .then((response) => response.data);
    }
}
