import { IconProps } from '@chakra-ui/icons';
import { Icon } from '@chakra-ui/react';

export const PresentIcon = (props: IconProps) => {
    return (
        <Icon viewBox="0 0 21 22.32" {...props}>
            <path
                d="M13,22.32c-1.1,0-2-.9-2-2v-3c0-1.1,.9-2,2-2h6c1.1,0,2,.9,2,2v3c0,1.1-.9,2-2,2h-6Zm-11,0c-1.1,0-2-.9-2-2v-3c0-1.1,.9-2,2-2h6c1.1,0,2,.9,2,2v3c0,1.1-.9,2-2,2H2ZM13,14.32c-1.1,0-2-.9-2-2v-3c0-1.1,.9-2,2-2h6c1.1,0,2,.9,2,2v3c0,1.1-.9,2-2,2h-6Zm-11,0c-1.1,0-2-.9-2-2v-3c0-1.1,.9-2,2-2h6c1.1,0,2,.9,2,2v3c0,1.1-.9,2-2,2H2Zm7.51-7.09h-.05l-.74-.05,.11-.21c-.77-.56-1.47-1.21-2.09-1.95-.76-.93-.89-2.23-.33-3.29,.31-.91,1.1-1.58,2.05-1.73,.44,0,1.22,.22,1.55,1.67,.21,.9,.33,1.82,.35,2.74,.97-1.27,1.93-1.92,2.86-1.92,.09,0,.18,0,.27,.02,1.07,.15,1.69,.53,1.83,1.13,.17,.71-.36,1.48-1.61,2.36-1.18,.8-2.58,1.22-4.01,1.23-.1,0-.17,0-.2,0h0Zm.87-1.04c.99-.1,1.93-.45,2.76-1,1.07-.75,1.24-1.21,1.21-1.31-.01-.06-.23-.27-.99-.37-.04,0-.09-.01-.13-.01-1.03,0-2.18,1.57-2.85,2.69h0ZM7.33,2.14c-.37,.71-.3,1.57,.17,2.21,.52,.62,1.1,1.18,1.73,1.67,.24-1.37,.18-2.78-.19-4.13-.13-.56-.34-.89-.57-.89-.31,0-.82,.42-1.14,1.14h0Z"
                id="present-icon"
            />
        </Icon>
    );
};
