import { useSession } from 'next-auth/react';
import {
    createContext,
    ReactNode,
    useCallback,
    useContext,
    useEffect,
    useMemo,
    useState
} from 'react';
import { isObject } from 'underscore';

import useLocalStorage from '@/hooks/useLocalStorage';

import { validateShippingAddress } from '@/components/Checkout/checkout-helpers';

import { Product } from '@/models/api';
import { PaymentSummary } from '@/models/api/PaymentSummary';
import { AddressData } from '@/models/api/ProfileInfo';
import { CartFreeShippingProduct, PaymentMethod } from '@/models/props/PaymentDetailsProps';
import {
    BasketNotificationsProps,
    BasketProps,
    BasketUnloggedProps,
    CartSummary,
    ItemsProps,
    ItemsPropsUnlogged,
    ProductActionsDisabled
} from '@/models/props/ProductCardProps';
import { ShippingProps } from '@/models/props/ShippingProps';
import {
    CartError,
    ProductIdProps,
    ShoppingCartContextProps
} from '@/models/props/ShoppingCartContextProps';
import { ShippingMethod, ShippingType } from '@/models/ShippingType';
import { APP_STAGING } from '@/pages/_app';
import { BasketService, PresentService } from '@/services';

import { useApp } from './appContext';
import { useCurrentUser } from './currentUserContext';

type ShoppingCartProviderProps = {
    children: ReactNode;
};

const ShoppingCartContext = createContext({} as ShoppingCartContextProps);

export function useShoppingCart() {
    return useContext(ShoppingCartContext);
}

export function ShoppingCartProvider({ children }: ShoppingCartProviderProps) {
    const { orderingAllowed, disableBasket } = useApp();
    const { setItem, getItem, removeItem } = useLocalStorage();
    const { currentUser, updateMoneySummary } = useCurrentUser();
    const session = useSession();
    const [basketLocked, setBasketLocked] = useState<boolean>();
    const [expireTime, setExpireTime] = useState<string>('');
    const [editable, setEditable] = useState<boolean>(true);
    const [cartItems, setCartItems] = useState<ItemsProps[]>([]);
    const [cartItemsUnlogged, setCartItemsUnlogged] = useState<ItemsPropsUnlogged[]>([]);
    const [currentBasket, setCurrentBasket] = useState<BasketProps>();
    const [basketUnlogged, setBasketUnlogged] = useState<BasketUnloggedProps>();
    const [basketUnloggedClear, setBasketUnloggedClear] = useState<boolean>(false);
    const [fetchingData, setFetchingData] = useState(false);
    const [basketEmpty, setBasketEmpty] = useState<boolean>();
    const [basketNotifications, setBasketNotifications] = useState<BasketNotificationsProps | null>(
        null
    );
    const [selectedShippingMethod, setSelectedShippingMethod] = useState<ShippingType>();
    const [searchedProduct, setSearchedProduct] = useState<Product | null>(null);
    const [shippingProduct, setShippingProduct] = useState<CartFreeShippingProduct | null>(null);
    const [selectedPaymentMethod, setSelectedPaymentMethod] = useState<PaymentMethod>('');
    const [eInvoice, setEInvoice] = useState<boolean>(true);
    const [shippingOptions, setShippingOptions] = useState<ShippingProps | null>(null);
    const [basketIsDisabled, setBasketIsDisabled] = useState<boolean>(false);
    const [activeProduct, setActiveProduct] = useState<ProductActionsDisabled | null>(null);
    const [cartError, setCartError] = useState<CartError | null>(null);

    const currency = useMemo(() => currentBasket?.summary?.currency || '', [currentBasket]);

    const cartQuantity: number =
        cartItems.length === 0
            ? 0
            : cartItems.reduce((quantity, item) => +item.quantity + quantity, 0);

    const cartQuantityUnlogged: number =
        cartItemsUnlogged.length === 0
            ? 0
            : cartItemsUnlogged.reduce((quantity, item) => +item.quantity + quantity, 0);

    const obj = useMemo(
        () => ({
            setItem,
            removeItem,
            session
        }),
        [setItem, removeItem, session]
    );

    const increaseHandler = useCallback(
        async (currItems: ItemsProps[], code: ProductIdProps) => {
            let items;
            setFetchingData(true);

            setCartItems(() => {
                if (currItems.find((item) => item.code === code) == null) {
                    items = [...currItems, { code: code, quantity: 1 }];

                    return items;
                }

                return currItems.map((item) => {
                    if (item.code === code) {
                        items = { ...item, quantity: +item.quantity + 1 };
                        setItem('shopping-cart', JSON.stringify(items), 'local');

                        return { ...item, quantity: +item.quantity + 1 };
                    }

                    setItem('shopping-cart', JSON.stringify(item), 'local');

                    return item;
                });
            });
        },
        [setItem]
    );

    const unloggedIncreaseHandler = useCallback(
        async (
            cartItemsUnlogged: ItemsPropsUnlogged[],
            code: ProductIdProps,
            grossPrice: number,
            quantity: number,
            catalogue?: boolean
        ) => {
            let items;
            const isCatalogue = catalogue ?? false;

            setFetchingData(true);
            setCartItemsUnlogged(() => {
                if (cartItemsUnlogged.find((item) => item.code === code) == null) {
                    const price = +(quantity * grossPrice).toFixed(2);

                    items = [
                        ...cartItemsUnlogged,
                        { code, grossPrice: price, quantity, catalogue: isCatalogue }
                    ];

                    return items;
                }

                return cartItemsUnlogged.map((item) => {
                    if (item.code === code) {
                        const q: number = +item.quantity + quantity;
                        const price = +(q * grossPrice).toFixed(2);

                        items = { ...item, grossPrice: price, quantity: q, isCatalogue };
                        setItem('shopping-cart-unlogged', JSON.stringify(items), 'local');

                        return {
                            ...item,
                            grossPrice: price,
                            quantity: +item.quantity + quantity,
                            isCatalogue
                        };
                    }

                    setItem('shopping-cart-unlogged', JSON.stringify(item), 'local');

                    return item;
                });
            });
        },
        [setCartItemsUnlogged, setItem]
    );

    const decreaseHandler = useCallback(async (currItems: ItemsProps[], code: ProductIdProps) => {
        setCartItems(() => {
            if (currItems.find((item) => item.code === code)?.quantity === 1) {
                return currItems.filter((item) => item.code !== code);
            }

            return currItems.map((item) => {
                if (item.code === code) {
                    return { ...item, quantity: +item.quantity - 1 };
                }

                return item;
            });
        });
    }, []);

    const updateItemHandler = useCallback(
        async (currItems: ItemsProps[], code: ProductIdProps, quantity: number | string) => {
            setCartItems(() => {
                if (+quantity < 1) {
                    return currItems.filter((item) => item.code !== code);
                }

                return currItems.map((item) => {
                    if (item.code === code) {
                        return { ...item, quantity: +quantity };
                    }

                    return item;
                });
            });
        },
        []
    );

    const updateItemHandlerUnlogged = useCallback(
        async (
            cartItemsUnlogged: ItemsPropsUnlogged[],
            code: ProductIdProps,
            grossPrice: number,
            quantity: number | string,
            catalogue?: boolean
        ) => {
            const isCatalogue = catalogue ?? false;
            const updatedItems =
                +quantity < 1
                    ? cartItemsUnlogged.filter((item) => item.code !== code)
                    : cartItemsUnlogged.map((item) => {
                          const price = +(+quantity * grossPrice).toFixed(2);

                          if (item.code === code) {
                              return {
                                  ...item,
                                  grossPrice: price,
                                  quantity: +quantity,
                                  isCatalogue
                              };
                          }

                          return item;
                      });

            setCartItemsUnlogged(() => updatedItems);

            return updatedItems;
        },
        []
    );

    async function updateItemQuantityUnlogged(
        code: ProductIdProps,
        quantity: number | string,
        grossPrice: number
    ) {
        setFetchingData(true);
        const basket = await updateItemHandlerUnlogged(
            cartItemsUnlogged,
            code,
            grossPrice,
            quantity
        ).finally(() => setFetchingData(false));

        if (basket.length === 0) {
            setCartItemsUnlogged(() => []);
            setBasketEmpty(true);

            return;
        }

        setBasketEmpty(false);
    }

    const removeItemHandler = useCallback(
        async (code: ProductIdProps) => {
            setFetchingData(true);

            setCartItems((cartItems) => {
                const items = cartItems.filter((item) => item.code !== code);

                obj.setItem('shopping-cart', JSON.stringify(items), 'local');

                return items;
            });
        },
        [obj]
    );

    const removeItemUnloggedHandler = useCallback(
        async (code: ProductIdProps) => {
            setFetchingData(true);
            const items = cartItemsUnlogged.filter((item) => item.code !== code);

            setCartItemsUnlogged(() => {
                obj.setItem('shopping-cart-unlogged', JSON.stringify(items), 'local');

                return items;
            });

            return items;
        },
        [obj, cartItemsUnlogged]
    );

    const getCurrentBasketCallback = useCallback(async () => {
        let basket: BasketProps;

        try {
            basket = await BasketService.getCurrentBasketHandler();

            if (!isObject(basket)) {
                return {
                    products: []
                };
            }

            const codeList = basket.products.map((item) => ({
                code: item.code,
                quantity: item.quantity
            }));

            setCartItems(() => codeList);

            if (basket.controls) {
                setExpireTime(basket.controls.expiresAt);
                setEditable(basket.controls.editable);
            }

            setCurrentBasket(() => basket);

            setFetchingData(() => false);
            updateMoneySummary();

            return basket;
        } catch (error) {
            // eslint-disable-next-line no-console
            console.error('Cannot load Basket Handler', error);

            return {
                products: []
            };
        }
    }, [updateMoneySummary]);

    const getBasketUnlogged = useCallback(
        async (items: ItemsPropsUnlogged[]): Promise<BasketUnloggedProps> => {
            let totalValue = 0;

            for (const item of items) {
                totalValue += +item.grossPrice;
            }

            const result: BasketUnloggedProps = {
                total: +totalValue.toFixed(2)
            };

            return result;
        },
        []
    );

    const getCurrentBasketUnloggedCallback = useCallback(async () => {
        const productsItem = getItem('shopping-cart-unlogged', 'local');
        const products: ItemsPropsUnlogged[] = productsItem ? JSON.parse(productsItem) : [];

        if (products.length === 0) return [];

        const codeList = products.map((item) => ({
            code: item.code,
            quantity: item.quantity,
            grossPrice: item.grossPrice
        }));

        setCartItemsUnlogged(codeList);
        await getBasketUnlogged(codeList);

        setFetchingData(false);

        return products;

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [getBasketUnlogged]);

    const getShippingCallback = useCallback(async () => {
        const getShipping: ShippingProps = await BasketService.getShippingHandler();

        setShippingOptions(getShipping);
        setFetchingData(false);

        return getShipping;
    }, []);

    const getPaymentCallback = useCallback(async () => {
        let getPayment;
        try {
            getPayment = await BasketService.getPaymentHandler();
        } catch (error) {
            // eslint-disable-next-line no-console
            console.log('Payment not found', error);
        }

        setFetchingData(false);

        return getPayment;
    }, []);

    const updateShippingOptions = useCallback((data: ShippingProps) => {
        setShippingOptions(data);
    }, []);

    const updateShippingOptionsCosts = useCallback((costs: Record<string, string>) => {
        setShippingOptions((prevState) => {
            return {
                ...prevState,
                shippingCost: costs
            } as ShippingProps;
        });
    }, []);

    function getItemQuantity(id: ProductIdProps) {
        return Number(cartItems.find((item) => item.code === id)?.quantity) || 0;
    }

    const clearCart = useCallback(async () => {
        {
            if (selectedShippingMethod === ShippingMethod.locker) {
                const resp = await BasketService.setShippingAddress(null);
                const res = await BasketService.getShippingHandler();

                updateSelectedShippingMethod(ShippingMethod.courier);
                updateShippingAddress(resp.shipping.address);
                updateShippingMethod(resp.shipping.shippingMethod);
                updateBasketSummary(resp.summary);
                updateShippingOptions(res);
                validateShippingAddress(resp.shipping.address);
            }

            setCartItems(() => []);
            await BasketService.deleteBasket();
            await getCurrentBasketCallback();

            setBasketEmpty(true);
        }
    }, [getCurrentBasketCallback, selectedShippingMethod, updateShippingOptions]);

    async function clearCartUnlogged() {
        setCartItemsUnlogged(() => []);
        removeItem('shopping-cart-unlogged', 'local');
    }

    async function increaseItemQuantity(code: ProductIdProps, quantity: number) {
        const data = await BasketService.updateQuantityHandler(code, quantity).finally(() =>
            setFetchingData(() => false)
        );

        await increaseHandler(cartItems, code);
        await getCurrentBasketCallback();
        setBasketNotifications(data.notifications);

        setBasketEmpty(false);

        return data;
    }

    async function unloggedIncreaseItemQuantity(
        code: ProductIdProps,
        quantity: number,
        grossPrice: number,
        catalogue?: boolean
    ) {
        const isCatalogue = catalogue ?? false;

        await unloggedIncreaseHandler(cartItemsUnlogged, code, grossPrice, quantity, isCatalogue);

        setFetchingData(false);
        setBasketEmpty(false);
    }

    async function updateItemQuantity(code: ProductIdProps, quantity: number | string) {
        setFetchingData(true);

        try {
            const data = await BasketService.updateQuantityHandler(code, quantity);
            await updateItemHandler(cartItems, code, quantity);
            const basket = await getCurrentBasketCallback();

            setBasketNotifications(data.notifications);

            if (basket.products.length === 0) {
                await clearCart();
                setBasketEmpty(true);
            } else {
                setBasketEmpty(false);
            }

            return data;
        } finally {
            setFetchingData(false);
        }
    }

    async function decreaseItemQuantity(code: ProductIdProps, quantity: number) {
        await decreaseHandler(cartItems, code);
        const data = await BasketService.updateQuantityHandler(code, quantity);
        const basket = await getCurrentBasketCallback();
        setBasketNotifications(data.notifications);

        if (basket.products.length === 0) {
            await clearCart();
        }

        return data;
    }

    async function removeFromCart(code: ProductIdProps) {
        await removeItemHandler(code);
        const data = await BasketService.deleteItemHandler(code);
        const basket = await getCurrentBasketCallback();
        setBasketNotifications(data.notifications);

        if (basket.products.length === 0) {
            await clearCart();
        }

        return data;
    }

    async function removeFromCartUnlogged(code: ProductIdProps) {
        const basket = await removeItemUnloggedHandler(code).finally(() => setFetchingData(false));
        await getCurrentBasketUnloggedCallback();

        if (basket.length === 0) {
            setCartItems(() => []);
            setBasketEmpty(true);
        }
    }

    const clearBasketUnlogged = useCallback(async () => {
        setCartItemsUnlogged(() => []);
        setBasketUnloggedClear(true);
        removeItem('shopping-cart-unlogged', 'local');
    }, [removeItem]);

    async function getShipping() {
        setFetchingData(true);

        return await getShippingCallback().finally(() => setFetchingData(false));
    }

    async function getPayment() {
        return await getPaymentCallback();
    }

    function setEInvoiceSelected(checked: boolean) {
        setEInvoice(checked);
    }

    async function setPaymentMethod(paymentMethod: PaymentMethod) {
        setSelectedPaymentMethod(paymentMethod);
    }

    async function makeOrder() {
        const data: PaymentSummary = await BasketService.makeOrderHandler();
        await getCurrentBasketCallback();

        if (data) setCartItems(() => []);

        return data;
    }

    async function setSearchProduct(product: Product | null) {
        setSearchedProduct(product);
    }

    function setFreeShippingProduct(product: CartFreeShippingProduct) {
        setShippingProduct(() => product);
    }

    function updateShippingAddress(newShippingData: AddressData) {
        setCurrentBasket((prevState) => {
            return {
                ...prevState,
                shipping: {
                    ...(prevState?.shipping || {}),
                    address: {
                        ...newShippingData
                    }
                }
            } as BasketProps;
        });
    }

    function updateShippingMethod(newShippingMethod: ShippingMethod) {
        setCurrentBasket((prevState) => {
            return {
                ...prevState,
                shipping: {
                    ...(prevState?.shipping || {}),
                    shippingMethod: newShippingMethod
                }
            } as BasketProps;
        });
    }

    function updateBasket(newState: Partial<BasketProps>) {
        setCurrentBasket((prevState) => {
            return {
                ...prevState,
                ...newState
            } as BasketProps;
        });
    }

    function updateBasketSummary(newSummary: CartSummary) {
        setCurrentBasket((prevState) => {
            return {
                ...prevState,
                summary: newSummary
            } as BasketProps;
        });
    }

    function updateSelectedShippingMethod(option: ShippingMethod) {
        setSelectedShippingMethod(option);
    }

    async function addPresentItem(
        promotionCode: string,
        productCode: ProductIdProps,
        quantity: number
    ) {
        setFetchingData(true);
        const data = await PresentService.addPresent(promotionCode, productCode, quantity);
        await getCurrentBasketCallback().finally(() => setFetchingData(false));

        return data;
    }

    async function updatePresentItem(
        promotionCode: string,
        productCode: ProductIdProps,
        quantity: number
    ) {
        setFetchingData(true);
        try {
            const data = await PresentService.updatePresent(promotionCode, productCode, quantity);
            await getCurrentBasketCallback().finally(() => setFetchingData(false));

            return data;
        } catch (e) {
            setFetchingData(false);
            throw e;
        }
    }

    async function deletePresentItem(promotionCode: string, code: ProductIdProps) {
        setFetchingData(true);
        const data = await PresentService.deletePresent(promotionCode, code);
        await getCurrentBasketCallback().finally(() => setFetchingData(false));

        return data;
    }

    async function disableCartActions(disable: boolean) {
        setBasketIsDisabled(disable);
    }

    async function clearBasketNotifications() {
        setBasketNotifications(null);
    }

    function setActiveItem(item: ProductActionsDisabled | null) {
        setActiveProduct(item);
    }

    async function updateCartError(error: CartError | null) {
        setCartError(error);
    }

    function updateCartItems(items: ItemsProps[]) {
        setCartItems(items);
    }

    useEffect(() => {
        if (session.status === 'authenticated' && APP_STAGING > 1) {
            getCurrentBasketCallback();
        } else {
            getCurrentBasketUnloggedCallback();
        }
    }, [session, getCurrentBasketCallback, getCurrentBasketUnloggedCallback]);

    useEffect(() => {
        obj.setItem('shopping-cart', JSON.stringify(cartItems), 'local');
    }, [cartItems, obj]);

    useEffect(() => {
        if (obj.session.status === 'unauthenticated') {
            obj.setItem('shopping-cart-unlogged', JSON.stringify(cartItemsUnlogged), 'local');
        }
    }, [cartItemsUnlogged, obj]);

    useEffect(() => {
        const updateBaskeUnlogged = async () => {
            const basket = await getBasketUnlogged(cartItemsUnlogged);

            setBasketUnlogged(basket);
        };

        updateBaskeUnlogged();
    }, [cartItemsUnlogged, getBasketUnlogged]);

    useEffect(() => {
        if (currentUser) {
            const { internationalSponsor } = currentUser;
            const { internationalSponsorNo, internationalSponsorStatus } = internationalSponsor;

            if (!internationalSponsorNo || !internationalSponsorStatus) {
                setBasketLocked(false);
                return;
            }

            const sponsorHasKeys = Object.keys(internationalSponsor).length > 0;

            if (
                sponsorHasKeys &&
                internationalSponsorNo.length > 0 &&
                ['ACCEPTED', 'PENDING'].includes(internationalSponsorStatus)
            ) {
                setBasketLocked(true);
                disableBasket();

                if (cartQuantity) {
                    clearCart();
                }
            } else {
                setBasketLocked(false);
            }
        } else {
            setBasketLocked(false);
        }
    }, [currentUser, cartQuantity, clearCart, disableBasket]);

    useEffect(() => {
        if (!orderingAllowed) {
            clearBasketUnlogged();
        }
    }, [clearBasketUnlogged, orderingAllowed]);

    return (
        <ShoppingCartContext.Provider
            value={{
                basketUnlogged,
                basketUnloggedClear,
                basketLocked,
                clearBasketUnlogged,
                currency,
                getItemQuantity,
                increaseItemQuantity,
                decreaseItemQuantity,
                updateItemQuantity,
                removeFromCart,
                removeFromCartUnlogged,
                clearCart,
                clearCartUnlogged,
                basketEmpty,
                cartItems,
                cartQuantity,
                cartQuantityUnlogged,
                editable,
                expireTime,
                currentBasket,
                eInvoice,
                fetchingData,
                getShipping,
                getPayment,
                setEInvoiceSelected,
                setPaymentMethod,
                selectedPaymentMethod,
                makeOrder,
                setSearchProduct,
                searchedProduct,
                freeShippingProduct: shippingProduct,
                setFreeShippingProduct,
                updateBasket,
                updateBasketSummary,
                updateShippingAddress,
                addPresentItem,
                updatePresentItem,
                deletePresentItem,
                shippingOptions,
                updateShippingOptions,
                disableCartActions,
                basketIsDisabled,
                basketNotifications,
                clearBasketNotifications,
                setActiveItem,
                activeProduct,
                unloggedIncreaseItemQuantity,
                cartItemsUnlogged,
                updateItemQuantityUnlogged,
                cartError,
                updateCartError,
                updateCartItems,
                updateShippingMethod,
                selectedShippingMethod,
                updateSelectedShippingMethod,
                updateShippingOptionsCosts
            }}
        >
            {children}
        </ShoppingCartContext.Provider>
    );
}
